import React, { useEffect, useState } from 'react'
import { ArrowRightIcon, CheckmarkIcon, CrossIcon, LoadingSpinner, MainButton, PlusIcon } from 'swap-frontend-library'
import AppointmentRow from './components/AppointmentRow'
import { getSelectionPresentations } from '../../../../../../../data/SelectionPortalRequests'
import { useOutletContext } from 'react-router-dom'
import AddPresentationModal from './components/AddPresentationModal'

const PresentationOverview = () => {
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  const [ selectionPresentations, setSelectionPresentations ] = useState<any[]>();
  const [ addPresentationModalOpen, setAddPresentationModalOpen ] = useState(false);

  const handleGetSelectionPresentations = async () => {
    const response = await getSelectionPresentations(selectionId);
    if (response.ok) {
      console.log(response.data.data)
      setSelectionPresentations(response.data.data);
    }
  }
  
  useEffect(() => {
    handleGetSelectionPresentations();
  }, [])

  if (typeof selectionPresentations == "undefined") return <LoadingSpinner />

  return (
    <div className='flex flex-col gap-4 p-4'>
        {
          selectionPresentations.map((item:any, index:number) => (<AppointmentRow meetingItem={item} />))
        }

        <div 
        onClick={() => setAddPresentationModalOpen(true)}
        className={`flex items-center justify-center cursor-pointer shadow max-md:max-w-full h-32 mt-2 bg-white opacity-50 hover:opacity-100 transitions-all duration-500 cursor-pointer rounded-lg `}>
          <div className='text-gray-300 w-16 h-16 rotate-45'>
            <CrossIcon />
          </div>
        </div>
        { addPresentationModalOpen ? <AddPresentationModal modalOpen={addPresentationModalOpen} setModalOpen={setAddPresentationModalOpen} selectionId={selectionId} handleGetSelectionPresentations={handleGetSelectionPresentations} /> : <></> }
    </div>
  )
}

export default PresentationOverview