import React, { useEffect, useRef, useState } from 'react'
import { ArrowDownIcon, CheckmarkIcon, InfoIcon, MainButton, MessageIcon, TextInput } from 'swap-frontend-library';
import { updatePresentationEvaluationUser } from '../../../../../../../../../data/SelectionPortalRequests';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../redux/store';
import { socket } from '../../../../../../../../../_socket/socket';
import { useOutletContext } from 'react-router-dom';

interface props {
    scenario : any, 
    scenarioEvaluations: any[], 
    presentationId : string, 
    handleFetchScenarioEvaluations: () => {}
    liveScenarioTaskId: any, 
    setLiveScenarioTaskId: React.Dispatch<React.SetStateAction<any>>
}

const ScenarioEvaluation = ({scenario, scenarioEvaluations, presentationId, handleFetchScenarioEvaluations, liveScenarioTaskId, setLiveScenarioTaskId} : props) => {
    const [scenarioDescriptionTextareaHeight, setScenarioDescriptionTextareaHeight] = useState("inherit");
    const scenarioDescriptionTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [sortedUniqueCategories, setSortedUniqueCategories] = useState<any[]>();

    const toggleScenarioDescription = () => {
        if (typeof scenarioDescriptionTextareaRef.current === "undefined" || scenarioDescriptionTextareaRef.current == null) return;
        if (scenarioDescriptionTextareaHeight != "inherit") {
            setScenarioDescriptionTextareaHeight("inherit");
        } else {
            setScenarioDescriptionTextareaHeight(`${scenarioDescriptionTextareaRef.current.scrollHeight}px`);
        }
    }

    const handleGetUniqueScenarioCategories = () => {
        const cateogories = scenario.selectionScenarioTasks.map((item:any)=>item.selectionScenarioTaskCategory);
        const cateogoryIds = scenario.selectionScenarioTasks.map((item:any)=>item.selectionScenarioTaskCategory.id);
        const uniqueCategories = cateogories.filter((item:any, index:number) => (cateogoryIds.indexOf(item.id) === index));
        const newSortedUniqueCategories =  uniqueCategories.sort((a:any, b:any) => a.orderNumber-b.orderNumber)
        setSortedUniqueCategories(newSortedUniqueCategories);
        // console.log(newSortedUniqueCategories)
    }

    useEffect(() => {
        handleGetUniqueScenarioCategories();
    }, [scenario])

    return (
    <div className='p-4'>
        <div className="relative flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full">
            <div className='mb-2'>
                <p className='text-sm font-semibold'>Title</p>
                <p className="w-full rounded text-xl font-bold tracking-tight leading-9 text-gray-900">{scenario.title}</p>
            </div>

            <div>
                <div onClick={() => toggleScenarioDescription()}
                className='flex items-center space-x-4 font-semibold text-lg cursor-pointer'>
                    <p className='text-sm font-semibold'>Description</p>
                    { scenarioDescriptionTextareaHeight != "inherit" ? <div className='rotate-180 w-6 h-6'><ArrowDownIcon /></div> : <div className='rotate-0 w-6 h-6'><ArrowDownIcon /></div>}
                </div>

                {scenario.description}

                <div onClick={() => toggleScenarioDescription()} className='w-fit text-primary text-sm italic cursor-pointer hover:underline hover:underline-offset-3'>{scenarioDescriptionTextareaHeight != "inherit" ? "Less" : "More"}</div>
            </div>
        </div>

        <div className='flex flex-col'>
            {
                typeof sortedUniqueCategories === "undefined" ? <></> : sortedUniqueCategories.map((category:any) => (
                    <ScenarioCategoryRow scenarioCategory={category}>
                        {
                            scenario.selectionScenarioTasks.filter((item:any) => item.selectionScenarioTaskCategory.id == category.id).map((task:any) => (
                                <ScenarioTaskRow scenario={scenario} scenarioTask={task} presentationId={presentationId} evaluation={scenarioEvaluations[task.id]} handleFetchScenarioEvaluations={handleFetchScenarioEvaluations} liveScenarioTaskId={liveScenarioTaskId} setLiveScenarioTaskId={setLiveScenarioTaskId} />
                            ))
                        }
                    </ScenarioCategoryRow>
                ))
            }
        </div>
    </div>
  )
}

const ScenarioCategoryRow = ({children, scenarioCategory} : {children : any, scenarioCategory : any}) => {
    const [open, setOpen] = useState(true);
    return (
        <div className='flex flex-col gap-2 mt-6 bg-white p-4 rounded-lg shadow'>
            <div onClick={() => setOpen(prev => !prev)} className='flex items-center gap-2 cursor-pointer'>
                <p className='font-semibold'>{scenarioCategory.title}</p>
                <div className={`w-4 h-4 ${open ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
            </div>
            {
                open ? (
                    <div className='flex flex-col gap-2'>
                        {children}
                    </div>
                ) : (<></>)
            }
        </div>
    )
}


interface scenarioTaskRowProps {
    scenario: any
    scenarioTask : any, 
    evaluation: any, 
    presentationId : string, 
    handleFetchScenarioEvaluations: () => {},
    liveScenarioTaskId: any, 
    setLiveScenarioTaskId: React.Dispatch<React.SetStateAction<any>>
}

const ScenarioTaskRow = ({scenario, scenarioTask, evaluation, presentationId, handleFetchScenarioEvaluations, liveScenarioTaskId, setLiveScenarioTaskId} : scenarioTaskRowProps) => {
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [rating, setRating] = useState<number>();
    const [comment, setComment] = useState<string | null>(null);

    // console.log(scenarioTask.id, evaluation)
    
    const isLive = scenarioTask.id == liveScenarioTaskId?.liveScenarioTaskId;
    const [open, setOpen] = useState(isLive);

    const handleSetRating = async ({rating} : any) => {
        await updatePresentationEvaluationUser(presentationId, scenarioTask.id, null, rating);
        handleFetchScenarioEvaluations();
    }
    
    const handleSetComment = async () => {
        if (comment != null) await updatePresentationEvaluationUser(presentationId, scenarioTask.id, comment);
        handleFetchScenarioEvaluations();
    }

    useEffect(() => {
        if (typeof evaluation !== "undefined" && typeof evaluation.rating !== "undefined") setRating(evaluation.rating);
        if (typeof evaluation !== "undefined" && typeof evaluation.comment !== "undefined") setComment(evaluation.comment);
    }, [evaluation])
    
    useEffect(() => {
        setOpen(scenarioTask.id == liveScenarioTaskId?.liveScenarioTaskId);
    }, [liveScenarioTaskId])
 
    return (
        <div onClick={() => setOpen(prev => !prev)} className={`cursor-pointer w-full rounded-lg shadow p-4 flex flex-col gap-4 ${isLive ? "bg-primary3 text-white" : "bg-white"}`}>
            <div className={`grid grid-cols-12 gap-4`}>
                <p className='col-span-1 flex items-center justify-start font-semibold'>{scenarioTask.cleanOrderNumber}</p>
                <div className={`${open ? "" : "truncate"} col-span-6`}>
                    <span className='font-semibold inline'>{scenarioTask.title}:</span> {scenarioTask.description}
                </div>
                <div className={`col-span-3 flex items-center justify-center space-x-1 items-center group`}>
                    {
                        [...Array(10).keys()].map((ithDot: any, index: number) => {
                            return (
                                <div onClick={(e) => {e.stopPropagation(); handleSetRating({rating: ithDot+1});}}
                                className={`w-4 h-4 ${(ithDot < Math.ceil((typeof rating !== "undefined" ? rating : 0))) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full`}></div>
                            )
                        })
                    }
                </div>
                <div className={`col-span-1 flex items-center justify-center`}>
                    <div className='relative w-6 h-6'>
                        <MessageIcon />
                        {
                            comment != null && comment != "" ? (
                                <div className='absolute rounded-full w-3 h-3 bg-primary right-0 bottom-0'></div>
                            ) : <></>
                        }
                    </div>
                </div>
                {
                    projectRole == "selection_admin" ? (
                        <div className='col-span-1 flex items-center'>
                            <div onClick={(e) => {e.stopPropagation(); socket.emit("set active scenarioTaskId", { presentationId, liveScenarioId: scenario.id, liveScenarioTaskId: scenarioTask.id });}} className='w-full bg-primary3 rounded p-1 text-center font-semibold text-white hover:shadow'>
                                {
                                    isLive ? "Live" : "Set live"
                                }
                            </div>
                        </div>
                    ) : (isLive) ? (
                        <div className='col-span-1 flex items-center'>
                            <div className='w-full bg-primary3 rounded p-1 text-center font-semibold text-white hover:shadow'>
                                Live
                            </div>
                        </div>

                    ) : (<></>)
                }
            </div>
            {
                open ? (
                <div onClick={(e) => {e.stopPropagation()}} className='w-full gap-2'>
                    <p className='text-sm'>Comment</p>
                    <TextInput textValue={comment} setTextValue={setComment} onBlur={handleSetComment} />
                </div>
                ) : <></>
            }
        </div>
    )
}

export default ScenarioEvaluation